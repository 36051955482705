import React from "react";
import "./Contact.css";
import "../custom.css";
import "./shared.css";
import { pageWidths } from "../page-widths";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SubmitButton from "../svgs/Submit.Main.svg";
import { Helmet } from "react-helmet";

function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [numAnswer, setNumAnswer] = React.useState("");
  const [ranNumOne, setRanNumOne] = React.useState(0);
  const [ranNumTwo, setRanNumTwo] = React.useState(0);
  const [showVerification, setShowVerification] = React.useState(false);
  const [hideSubmitted, setHideSubmitted] = React.useState(true);
  const [failedSubmission, setFailedSubmission] = React.useState(false);
  const [failedSubmissionText, setFailedSubmissionText] =
    React.useState("Failed to Submit!");
  const [width, setWidth] = React.useState(window.innerWidth);

  // Show the verification question
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function TriggerHumanCheck() {
    setRanNumOne(Math.floor(Math.random() * 50));
    setRanNumTwo(Math.floor(Math.random() * 50));
    setShowVerification(true);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      TriggerHumanCheck();
    }
  };

  // Submit the contact form
  function SubmitContactForm() {
    // Check if the verification answer is correct and all the required fields are filled
    if (
      ranNumOne + ranNumTwo === parseInt(numAnswer) &&
      name &&
      email &&
      subject &&
      message
    ) {
      console.log(`${name} - ${email} - ${phone} - ${subject} - ${message}`);
      PostContactFormSubmission();
    } else {
      setFailedSubmission(true);
    }
    setShowVerification(false);
  }

  // Send a POST request to the server with the form data
  function PostContactFormSubmission() {
    const ContactUsForm = {
      Message: message,
      Subject: subject,
      Phone: phone,
      Email: email,
      FullName: name,
    };

    let jsonFormData = JSON.stringify(ContactUsForm);

    fetch("https://rll2hxh5a8.execute-api.us-east-2.amazonaws.com/submitform", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonFormData,
    })
      .then((response) => {
        if (!response.ok) {
          setFailedSubmission(true);
          setHideSubmitted(true);
          throw new Error("Form submission failed");
        }
        console.log("Form submitted successfully");
        setFailedSubmission(false);
        setHideSubmitted(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function clickPress(event) {
    if (event.key === "Enter") {
      SubmitContactForm();
    }
  }

  return (
    <div
      style={{
        marginLeft:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        marginRight:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        paddingLeft:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
        paddingRight:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions | Contact</title>
        <meta
          name="description"
          content="The first step to any custom project is discovery! We put an extreme amount of effort upfront so we can understand your needs and how we can help!"
        />
        <link
          rel="canonical"
          href="https://www.radixsoftwaresolutions.com/contact"
        />
      </Helmet>
      <div
        className="contactingUs"
        style={{
          backgroundColor: "white",
          paddingLeft: width >= pageWidths.smallWidth ? "7em" : "3em",

          paddingRight: width >= pageWidths.smallWidth ? "5em" : "3em",
        }}
      >
        <h1
          className="headerTextStyle"
          style={{
            textAlign: "center",
            paddingTop: width > pageWidths.smallWidth ? "1em" : "none",
            paddingBottom: "1em",
          }}
        >
          CONTACT US
        </h1>

        <p className="paragraphTextStyle">
          We are excited about every new opportunity to build or improve custom
          solutions! Whether it be Custom Reporting or Building Integrations, our
          discovery process begins with a simple 1-hour long meeting.
          <br />
          <br />
          {/* Completing the form below with some initial information helps us to
          prepare for the discovery meeting and gives us an email address to
          email you back. */}
        </p>
      </div>
      <br />
      <br />
      <div
        className="getInTouch"
        style={{
          textAlign: "center",
        }}
      >
        {/* <h1 className="headerTextStyle" style={{ color: "#278a90" }}>
          Get in Touch
        </h1> */}
        <label style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}>
          We are so sorry, but we are currently not accepting new clients!
        </label>
        {/* <form
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "3vw",
            paddingRight: "3vw",
            borderLeft: "1em solid #d65e38",
            borderRight: "1em solid #d65e38",
          }}
        >
          
          <label
            style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}
          >
            Full Name<span style={{ color: "red" }}>*</span>
            <br />
            <input
              className="formInput"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your full name"
              required
            />
          </label>

         
          <label
            style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}
          >
            Email<span style={{ color: "red" }}>*</span>
            <br />
            <input
              className="formInput"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </label>

         
          <label
            style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}
          >
            Phone
            <br />
            <input
              className="formInput"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
              required
            />
          </label>

          
          <label
            style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}
          >
            Subject<span style={{ color: "red" }}>*</span>
            <br />
            <input
              className="formInput"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter the subject of your request"
              required
            />
          </label>

          
          <label
            style={{ color: "#064860", textAlign: "left", paddingTop: "1em" }}
          >
            Message<span style={{ color: "red" }}>*</span>
            <br />
            <textarea
              maxLength="450"
              style={{
                background: "none",
                border: "1px solid grey",
                width: "85%",
                height: "10em",
                color: "#064860",
                resize: "none",
                boxSizing: "border-box",
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type a brief explanation of your request here..."
              required
            />
          </label>
        </form> */}

        {/* Anti Robo Stuff */}
        {/* <Modal
          open={showVerification}
          onClose={() => setShowVerification(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: width > 418 ? 400 : 250,
              bgcolor: "background.paper",
              border: "2px solid #000",
              p: 4,
            }}
          >
            <h5 style={{}}>
              What is {ranNumOne} plus {ranNumTwo}?
            </h5>
            <input
              style={{ marginTop: 2 }}
              className="formInput"
              type="number"
              value={numAnswer}
              onChange={(e) => setNumAnswer(e.target.value)}
              required
              onKeyDown={(event) => clickPress(event)}
              id="human-check-input"
            />
            <img
              style={{
                width: "6em",
                height: "2em",
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "33%",
              }}
              className="imageOnHover"
              src={SubmitButton}
              onClick={() => SubmitContactForm()}
              alt="Submit Form Button"
            />
          </Box>
        </Modal>

        <div
          style={{
            padding: !hideSubmitted ? "1em" : "none",
          }}
        >
          <span hidden={hideSubmitted} style={{ color: "green" }}>
            Successfully Submitted!
          </span>
          <span hidden={!failedSubmission} style={{ color: "red" }}>
            {failedSubmissionText} <br />
            This could be due to invalid information, failing the spam
            prevention, or too many requests.
          </span>
        </div> */}

        {/* Submit Button */}
        {/* <button
          style={{ width: "6em", height: "2em", paddingBottom: "3em" }}
          hidden={!hideSubmitted}
        >
          <img
            className="imageOnHover"
            src={SubmitButton}
            onClick={() => TriggerHumanCheck()}
            alt="Submit Form Button"
          />
        </button> */}
      </div>
    </div>
  );
}

export default Contact;
