import React, { Component } from "react";
import { Container } from "reactstrap";
import NavMenu from "./components/NavMenu";
import { Footer } from "./components/Footer";
import "./Layout.css";
import { colors } from "./colors.js";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div
        className="pageBackground"
        stlye={{
          backgroundColor: colors.warmWhite,
        }}
      >
        {/* Set header and body to always take up enough space to keep footer at bottom */}
        <div
          style={{
            minHeight: "90vh",
          }}
        >
          <NavMenu />
          <div
            style={{
              minHeight: "90vh",
            }}
          >
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
