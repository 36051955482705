import React from "react";
import FacebookIcon from "../svgs/Facebook.Orange.svg";
import InstagramIcon from "../svgs/Instagram.Orange.svg";
import TwitterIcon from "../svgs/Twitter.Orange.svg";
import LinkedInIcon from "../svgs/LinkedIn.Orange.svg";

import "./Home.css";
import DataAndReportingIcon from "../svgs/data-and-reporting-icon.svg";
import DesktopAppIcon from "../svgs/desktop-app-icon.svg";
import WebsiteIcon from "../svgs/website-icon.svg";
import { pageWidths } from "../page-widths";
import { Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./shared.css";
import { Helmet } from "react-helmet";
import Banner from "../images/WebsiteBanner-100.jpg";

function Home() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        marginLeft:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        marginRight:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        paddingLeft:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
        paddingRight:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions</title>
        <meta
          name="description"
          content="No more cookie cutter reports, integrations, and applications holding you back. We offer fully customized services for all of your tech needs!"
        />
        <link rel="canonical" href="https://www.radixsoftwaresolutions.com/" />
      </Helmet>
      {/* Banner */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          backgroundImage: `url(${Banner})`,
          backgroundPosition: "center center",
          backgroundSize: width > 1388 ? "cover" : "cover",
          backgroundRepeat: "no-repeat",
          height:
            width > 1388
              ? "18em"
              : width > 800
              ? "16em"
              : width > 558
              ? "11em"
              : "8em",
          alignItems: "flex-end",
          marginLeft: "auto",
          marginRight: "auto",
          minHeight:
            width > 1388
              ? "20em"
              : width > 800
              ? "16em"
              : width > 558
              ? "11em"
              : "8em",
          width: "100%",
        }}
        hidden={width < 415}
      >
        <div style={{ padding: ".5em", marginLeft: "auto" }}>
          <a
            href="https://www.facebook.com/people/Radix-Software-Solutions/100088133167937/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width={width > 800 ? "35px" : width > 558 ? "30px" : "25em"}
              src={FacebookIcon}
              alt="Custom Facebook Icon"
              className="socialIcon"
            />
          </a>
        </div>
        <div style={{ padding: ".5em" }}>
          <a
            href="https://www.instagram.com/radix_software_solutions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width={width > 800 ? "35px" : width > 558 ? "30px" : "25em"}
              src={InstagramIcon}
              alt="Custom Instagram Icon"
              className="socialIcon"
            />
          </a>
        </div>
        <div style={{ padding: ".5em" }}>
          <a
            href="https://twitter.com/Radix_Software"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width={width > 800 ? "35px" : width > 558 ? "30px" : "25em"}
              src={TwitterIcon}
              alt="Custom Twitter Icon"
              className="socialIcon"
            />
          </a>
        </div>
        <div style={{ padding: ".5em", marginRight: "auto" }}>
          <a
            href="https://www.linkedin.com/company/radix-software-solutions-llc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width={width > 800 ? "35px" : width > 558 ? "30px" : "25em"}
              src={LinkedInIcon}
              alt="Custom LinkedIn Icon"
              className="socialIcon"
            />
          </a>
        </div>
      </div>

      {/* Small Banner */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
        hidden={width >= 415}
      >
        <img src={Banner} alt="Radix Software Soluations Banner" width="100%" />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ padding: ".5em", marginLeft: "auto" }}>
            <a
              href="https://www.facebook.com/people/Radix-Software-Solutions/100088133167937/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="25px"
                src={FacebookIcon}
                alt="Custom Facebook Icon"
                className="socialIcon"
              />
            </a>
          </div>
          <div style={{ padding: ".5em" }}>
            <a
              href="https://www.instagram.com/radix_software_solutions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="25px"
                src={InstagramIcon}
                alt="Custom Instagram Icon"
                className="socialIcon"
              />
            </a>
          </div>
          <div style={{ padding: ".5em" }}>
            <a
              href="https://twitter.com/Radix_Software"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="25px"
                src={TwitterIcon}
                alt="Custom Twitter Icon"
                className="socialIcon"
              />
            </a>
          </div>
          <div style={{ padding: ".5em", marginRight: "auto" }}>
            <a
              href="https://www.linkedin.com/company/radix-software-solutions-llc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                width="25px"
                src={LinkedInIcon}
                alt="Custom LinkedIn Icon"
                className="socialIcon"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Service Options */}
      <h1
        className="headerTextStyle"
        style={{ paddingTop: width > pageWidths.smallWidth ? "1em" : "1em" }}
      >
        SERVICES
      </h1>
      <div
        style={{
          paddingTop: width > pageWidths.smallWidth ? "1em" : 0,
          paddingBottom: width > pageWidths.smallWidth ? "6em" : "none",
          display: "flex",
          flexDirection: width > pageWidths.smallWidth ? "row" : "column",
          flex: 2,
          alignItems: "flex-start",
          justifyContent: "center",

          backgroundColor: "white",
        }}
      >
        {/* Data and Reporting Box */}

        <div
          className="serviceSection"
          style={{
            borderLeft: "1em solid #d65e38",
            borderRight: "1em solid #d65e38",
            marginTop: width <= pageWidths.smallWidth ? "1em" : "none",
          }}
        >
          <Link to="/services">
            <div className="serviceHover serviceImageContainer">
              <img
                width="100%"
                src={DataAndReportingIcon}
                alt="Custom Data Grid and Chart Icon"
              />
            </div>
          </Link>
          <h3 className="headerTextStyle">Data and Reporting</h3>
          <p className="paragraphSnippetStyle">
            No more cookie cutter data reports or downloading csv files! See the
            data you need to make the most informed business decisions!
          </p>
          <Link
            style={{ color: "#42C0B7" }}
            className="serviceHover"
            to="/services"
          >
            Read More
          </Link>
        </div>

        {/* Integrations*/}
        <div
          className="serviceSection"
          style={{ marginTop: width <= pageWidths.smallWidth ? "1em" : "none",            borderRight: "1em solid #d65e38",
        }}
        >
          <Link to="/services">
            <div className="serviceHover serviceImageContainer">
              <img
                width="73%"
                src={WebsiteIcon}
                alt="Custom Website Template Icon"
              />
            </div>
          </Link>
          <h3 className="headerTextStyle">Custom Integrations</h3>
          <p className="paragraphSnippetStyle">
            We offer customized integrations to fit your business or personal needs.
            From API to any custom source - we connect it all!
          </p>
          <Link
            style={{ color: "#42C0B7" }}
            className="serviceHover"
            to="/services"
          >
            Read More
          </Link>
        </div>

        {/* SaaS */}
        {/* <div
          className="serviceSection"
          style={{
            borderLeft: "1em solid #d65e38",
            borderRight: "1em solid #d65e38",
            marginTop: width <= pageWidths.smallWidth ? "1em" : "none",
          }}
        >
          <Link to="/services">
            <div className="serviceImageContainer serviceHover">
              <img
                width="63%"
                src={DesktopAppIcon}
                alt="Custom Desktop Application Icon"
              />
            </div>
          </Link>
          <h3 className="headerTextStyle">Software Solutions</h3>
          <p className="paragraphSnippetStyle">
            Explore our range of Software as a Service (SaaS) platforms. Our SaaS offerings empower businesses with cutting-edge 
            technology.          
          </p>
          <Link
            style={{ color: "#42C0B7" }}
            className="serviceHover"
            to="/services"
          >
            Read More
          </Link>
        </div>*/}
      </div> 

      <h1
        className="headerTextStyle"
        style={{ color: "#D65E38", paddingTop: "0em" }}
      >
        FEATURED CLIENTS
      </h1>

      {/* Featured Client Spotlight - Small*/}
      <div
        style={{
          padding: "1em",
          display: "flex",
          flexDirection: width > pageWidths.smallWidth ? "row" : "column",
          flex: 2,
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "10%",
          marginRight: "10%",
          backgroundColor: "#064860",
          color: "white",
        }}
        hidden={width >= pageWidths.smallWidth}
      >
        {/* https://github.com/express-labs/pure-react-carousel#readme */}
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={3}
          orientation={"horizontal"}
          isIntrinsicHeight={true}
          visibleSlides={1}
          isPlaying={true}
          infinite={true}
          interval={4000}
          step={1}
          playDirection={"forward"}
        >
          <Slider>
            <Slide index={0}>
              {/* Brown's Top Shelf Meats*/}
              <div className="moibleServiceSection">
                <a
                  href="https://topshelfmeats.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="serviceHover serviceImageContainer"
                    style={{ backgroundColor: "black" }}
                  >
                    <img
                      width="100%"
                      src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Browns-TopShelf-Meats_logo.png"
                      alt="Top Shelf Meats Badge"
                    />
                  </div>
                </a>
                <p
                  className="paragraphSnippetStyle centerParagraph"
                  style={{ color: "white" }}
                >
                  <i>
                    ...Very personable, clear to understand, and has great ideas
                    to help. Highly recommend Nathan and Radix!
                  </i>
                </p>
              </div>
            </Slide>
            <Slide index={1}>
              {/* Back Road Picking*/}
              <div className="moibleServiceSection">
                <a
                  href="https://backroadpicking.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="serviceHover serviceImageContainer">
                    <img
                      width="100%"
                      src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Badge5.Main.png"
                      alt="Back Road Picking Badge"
                    />
                  </div>
                </a>
                <p
                  className="paragraphSnippetStyle centerParagraph"
                  style={{ color: "white" }}
                >
                  <i>
                    Nathan and team took us from a blank slate to a full blown
                    site that we love ... You won't go wrong working with Radix.
                  </i>
                </p>
              </div>
            </Slide>
            <Slide index={2}>
              {/* Paper Wishes */}
              <div className="moibleServiceSection">
                <a
                  href="https://paperwishes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="serviceHover" style={{}}>
                    <img
                      width="100%"
                      src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/PaperWishes.png"
                      alt="Paper Wishes Badge"
                    />
                  </div>
                </a>
                <p
                  className="paragraphSnippetStyle centerParagraph"
                  style={{ color: "white" }}
                >
                  <i>
                    ...I wouldn{"'"}t hesitate recommending Nathan and Radix. I
                    know he will be our go to for any future needs.
                  </i>
                </p>
              </div>
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>

      {/* Featured Client Spotlight - Medium and Big*/}
      <div
        style={{
          padding: "1em",
          paddingTop: "2em",
          display: "flex",
          flexDirection: width > pageWidths.smallWidth ? "row" : "column",
          flex: 2,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#064860",
          color: "white",
        }}
        hidden={width < pageWidths.smallWidth}
      >
        {/* Brown's Top Shelf Meats*/}
        <div className="serviceSection" style={{ backgroundColor: "#064860" }}>
          <a
            href="https://topshelfmeats.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="serviceHover serviceImageContainer"
              style={{ backgroundColor: "black" }}
            >
              <img
                width="60%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Browns-TopShelf-Meats_logo.png"
                alt="Top Shelf Meats Badge"
              />
            </div>
          </a>
          <p
            className="paragraphSnippetStyle centerParagraph"
            style={{ color: "white" }}
          >
            <i>
              ...Very personable, clear to understand, and has great ideas to
              help. Highly recommend Nathan and Radix!
            </i>
          </p>
        </div>

        {/* Back Road pIcking*/}
        <div className="serviceSection" style={{ backgroundColor: "#064860" }}>
          <a
            href="https://backroadpicking.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="serviceHover serviceImageContainer">
              <img
                width="100%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Badge5.Main.png"
                alt="Back Road Picking Badge"
              />
            </div>
          </a>
          <p
            className="paragraphSnippetStyle centerParagraph"
            style={{ color: "white" }}
          >
            <i>
              Nathan and team took us from a blank slate to a full blown site
              that we love ... You won't go wrong working with Radix.
            </i>
          </p>
        </div>

        {/* Paper Wishes */}
        <div className="serviceSection" style={{ backgroundColor: "#064860" }}>
          <a
            href="https://paperwishes.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="serviceHover serviceImageContainer" style={{}}>
              <img
                width="65%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/PaperWishes.png"
                alt="Paper Wishes Badge"
              />
            </div>
          </a>
          <p
            className="paragraphSnippetStyle centerParagraph"
            style={{ color: "white" }}
          >
            <i>
              ...I wouldn{"'"}t hesitate recommending Nathan and Radix. I know
              he will be our go to for any future needs.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
