import React, { useState } from "react";
import "./ScrollButton.css";
import "./ScrollButton.css";
import UpArrow from "../svgs/up-arrow.svg";
import { pageWidths } from "../page-widths";

const ScrollButton = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="scrollToTopImageDiv" hidden={width < pageWidths.smallWidth}>
      <img
        src={UpArrow}
        width="45px"
        style={{ display: visible ? "inline" : "none", paddingBottom: "2em" }}
        alt=""
        onClick={scrollToTop}
        className="link"
      />
    </div>
  );
};

export default ScrollButton;
