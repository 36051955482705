import React, { Component } from "react";

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    const currentYear = new Date().getFullYear();

    return (
      <footer
        style={{
          textAlign: "center",
          fontSize: "10pt",
          paddingTop: "2em",
        }}
      >
        <p>&copy;2022-{currentYear} by Radix Software Solutions LLC</p>
      </footer>
    );
  }
}
