import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavMenu.css";
import VerticalLine from "../svgs/vertical-line-divider.svg";
import BulbIcon from "../svgs/bulb-icon.svg";
import HamburgerMenu from "../svgs/bars-solid.svg";
import Drawer from "@mui/material/Drawer";
import { pageWidths } from "../page-widths";

function NavMenu() {
  const [width, setWidth] = React.useState(window.innerWidth);
  let [drawerOpen, setDrawerOpen] = React.useState(false);

  let location = useLocation();

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <header
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: width < pageWidths.smallWidth ? "sticky" : "none",
        top: width < pageWidths.smallWidth ? 0 : "none",
      }}
    >
      {/* Header Logo */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          paddingRight: "1%",
          paddingTop: ".25rem",
          paddingBottom: ".25rem",
        }}
      >
        <Link to="/">
          <img
            src={BulbIcon}
            width={width > 400 ? "35px" : "27px"}
            alt="Radix Software Solutions LLC Bulb Icon."
          />
        </Link>
      </div>

      {/* Header Navigation */}
      <div
        style={{
          display: width > 767 ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          className={location.pathname === "/" ? "navItemSelected" : "navItem"}
          style={{
            paddingLeft: ".45em",
            paddingRight: ".45em",
            fontSize: "1.2em",
          }}
        >
          HOME
        </Link>

        <img
          src={VerticalLine}
          width={width > 400 ? "35px" : "27px"}
          alt="Verticle Line Divider"
        />

        <Link
          to="/about"
          className={
            location.pathname === "/about" ? "navItemSelected" : "navItem"
          }
          style={{
            paddingLeft: ".45em",
            paddingRight: ".45em",
            fontSize: "1.2em",
          }}
        >
          ABOUT
        </Link>

        <img
          src={VerticalLine}
          width={width > 400 ? "35px" : "27px"}
          alt="Verticle Line Divider"
        />
        <Link
          to="/services"
          className={
            location.pathname === "/services" ? "navItemSelected" : "navItem"
          }
          style={{
            paddingLeft: ".45em",
            paddingRight: ".45em",
            fontSize: "1.2em",
          }}
        >
          SERVICES
        </Link>

        <img
          src={VerticalLine}
          width={width > 400 ? "35px" : "27px"}
          alt="Verticle Line Divider"
        />
        <Link
          to="/featured-clients"
          className={
            location.pathname === "/featured-clients"
              ? "navItemSelected"
              : "navItem"
          }
          style={{
            paddingLeft: ".45em",
            paddingRight: ".45em",
            fontSize: "1.2em",
          }}
        >
          FEATURED
        </Link>

        <img
          src={VerticalLine}
          width={width > 400 ? "35px" : "27px"}
          alt="Verticle Line Divider"
        />

        <Link
          to="/contact"
          className={
            location.pathname === "/contact" ? "navItemSelected" : "navItem"
          }
          style={{
            paddingLeft: ".45em",
            paddingRight: ".45em",
            fontSize: "1.2em",
          }}
        >
          CONTACT
        </Link>
      </div>

      {/* Header Navigation on Small Screen */}
      <div
        style={{
          display: width <= 767 ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "78%",
        }}
      >
        <div onClick={() => setDrawerOpen(true)}>
          <img
            src={HamburgerMenu}
            width={width > 400 ? "25px" : "22px"}
            alt="Radix Software Solutions LLC Bulb Icon."
          />
        </div>
        <Drawer
          anchor={"top"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(!drawerOpen)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to="/"
              className={
                location.pathname === "/" ? "navItemSelected" : "navItem"
              }
              style={{
                padding: ".45em",
                fontSize: "1.4em",
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              Home
            </Link>

            <Link
              to="/about"
              className={
                location.pathname === "/about" ? "navItemSelected" : "navItem"
              }
              style={{
                padding: ".45em",

                fontSize: "1.4em",
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              About
            </Link>

            <Link
              to="/services"
              className={
                location.pathname === "/services"
                  ? "navItemSelected"
                  : "navItem"
              }
              style={{
                padding: ".45em",

                fontSize: "1.4em",
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              Services
            </Link>

            <Link
              to="/featured-clients"
              className={
                location.pathname === "/featured-clients"
                  ? "navItemSelected"
                  : "navItem"
              }
              style={{
                padding: ".45em",

                fontSize: "1.4em",
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              Featured Clients
            </Link>

            <Link
              to="/contact"
              className={
                location.pathname === "/contact" ? "navItemSelected" : "navItem"
              }
              style={{
                padding: ".45em",

                fontSize: "1.4em",
              }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              Contact
            </Link>

            {/* <HorizontalLine /> */}
          </div>
        </Drawer>
      </div>
    </header>
  );
}

export default NavMenu;
