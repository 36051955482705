import React from "react";
import { pageWidths } from "../page-widths";
import "./shared.css";
import { Helmet } from "react-helmet";
import logo from "../images/VerticalWordMark.Main.png"

function About() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        marginLeft:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        marginRight:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        paddingLeft:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
        paddingRight:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
      }}
    >
      <h1
          className="headerTextStyle"
          style={{
            textAlign: "center",
            paddingTop: width > pageWidths.smallWidth ? "1em" : "none",
            paddingBottom: "1em",
          }}
        >
          ABOUT US
        </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions | About</title>
        <meta
          name="description"
          content="Radix Software Solutions has years of experience working closly with clients to improve processes. Learn a bit more about us and how we can help you with any business needs!"
        />
        <link
          rel="canonical"
          href="https://www.radixsoftwaresolutions.com/about"
        />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: width > 990 ? "row" : "column",
          alignItems: "center",
        }}
      >
        {/* Owner Picture */}
        <div
          style={{
            width: "100%",
            maxWidth: "20em",
            minWidth: width > 400 ? "20em" : "10em",
          }}
        >
          <img
            src={logo}
            alt="Radix Software Solutions LLC Logo"
            width={"100%"}
          />
        </div>

        {/* Hello Introduction */}
        <div
          style={{
            paddingLeft: "3em",
            paddingRight: "3em",
            paddingTop: width > 990 ? "2em" : "1em",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="paragraphTextStyle">
              With years of expertise, we specialize in Custom Data Integrations, Custom Reports, and the 
              development and support of our custom SaaS platforms.
            <br />
            <i style={{ color: "#278a90" }}>
              At Radix, our primary objective is to streamline custom work with a
               commitment to honesty and clarity.

            </i>
          </p>
        </div>
      </div>
      {/* Radix Introduction */}
      <div
        style={{
          paddingLeft: "3em",
          paddingRight: "3em",
          marginTop: "4em",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderLeft: "1em solid #d65e38",
          borderRight: "1em solid #d65e38",
        }}
      >
        <p className="paragraphTextStyle">
          Radix Software Solutions LLC is headquartered in Kentucky, proudly serving both local businesses and a diverse clientele across the nation. Our inception stems from addressing prevalent challenges in the industry, including:

          <br />
          <br />

          &ensp;1. Overpriced work often outsourced to other countries.
          <br />
          &ensp;2. Delivering overly complex and fragile results.
          <br />
          <br />

          At Radix, we've set ourselves apart by keeping all our resources within the United States. From servers to databases and our dedicated team, everything is right here at home. This commitment ensures we deliver the highest quality results to our clients.
        </p>

      </div>
    </div>
  );
}

export default About;
