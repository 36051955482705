import React from "react";
import { pageWidths } from "../page-widths";
import "./shared.css";
import "./featuredClient.css";
import { Helmet } from "react-helmet";

function FeaturedClients() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        marginLeft:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        marginRight:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        paddingLeft:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
        paddingRight:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions | Featured Clients</title>
        <meta
          name="description"
          content="Our clients are our top priority and we take pride in their success! Check out a few of our featured clients and their testimonials!"
        />
        <link
          rel="canonical"
          href="https://www.radixsoftwaresolutions.com/featured-clients"
        />
      </Helmet>
      <h1
        className="headerTextStyle"
        style={{ paddingTop: width > pageWidths.smallWidth ? "1em" : "none" }}
      >
        FEATURED CLIENTS
      </h1>
      {/* Brown's Top Shelf Meats*/}
      <div
        className="clientSection"
        style={{ borderBottom: ".4em solid #d65e38", paddingTop: "4em" }}
      >
        <div className="client-header">
          <a
            href="https://topshelfmeats.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="imageContainer" style={{ backgroundColor: "black", borderRadius: '25px' }}>
              <img
                width="100%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Browns-TopShelf-Meats_logo.png"
                alt="Browns Top Shelf Meats Badge Icon"
              />
            </div>
          </a>
          <h3 className="headerTextStyle client-name">
            Browns Top Shelf Meats
          </h3>
        </div>

        <p className="paragraphTextStyle onePadding">
          I was referred to Nathan at Radix Software Solutions from a personal
          friend of mine that knew I was in desperate need of help. I had a
          problem on my site hosted on Shopify with how my product was being
          shown once added to a shopping cart. Shopify had no solution for this
          nor were there any 3rd party apps available to provide a remedy. 
          <br /><br />
          In
          comes Nathan, and no joking, had not only a theory on how to fix the
          issue, but a working test within 1 week. When I say he saved my
          business, I mean it! To add to that, he is great to work with as well.
          Very personable, clear to understand, and has great ideas to help.
          Highly recommend Nathan and Radix!
          <br />
          <br />
          <i>- Mike Brown, Owner</i>
        </p>
      </div>

      {/* Back Road Picking*/}
      <div
        className="clientSection"
        style={{ borderBottom: ".4em solid #d65e38", paddingTop: "4em" }}
      >
        <div className="client-header">
          <a
            href="https://backroadpicking.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="imageContainer">
              <img
                width="100%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/Badge5.Main.png"
                alt="Back Road Picking Badge Icon"
              />
            </div>
          </a>
          <h3 className="headerTextStyle client-name">
          Back Road Picking
          </h3>
        </div>
        <p className="paragraphTextStyle onePadding">
          As someone who has worked in eCommerce and in Tech (Saas) for more
          than 20 years I wanted to ensure my site was built in a manner that
          was easy to access, from any device, easy for me to update, and
          matched the style of what we are doing. Nate (Radix Solutions) hit it
          out of the park! This site is fantastic! It's responsive, the design
          is fantastic (TY Alexus!) and everything works as it should! 
          <br /><br />
          Nathan and team took us from a blank slate to a full blown site that
          we love! They took the time to figure out what our style was,
          presented us with options, the patiently worked with us (me) when we
          wanted something changed. You won't go wrong working with Radix. They
          communicate very well and are very professional. Great work, thank you
          so much!
          <br />
          <br />
          <i>- Kim Wren, Owner</i>
        </p>
      </div>

      {/* Paper Wishes */}
      <div className="clientSection" style={{ paddingTop: "4em" }}>
        <div className="client-header">
          <a
            href="https://paperwishes.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="imageContainer">
              <img
                width="100%"
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/PaperWishes.png"
                alt="Paper Wishes Badge Icon"
              />
            </div>
          </a>
          <h3 className="headerTextStyle client-name">
            Paper Wishes
          </h3>
        </div>
        <p className="paragraphTextStyle onePadding">
          I was creating a custom report out of SkuVault on a weekly basis. It
          took hours and I always questioned its accuracy. I needed to do
          something different. I was introduced to Nathan at Radix Software
          Solutions who was able to create a report for us within a week. Now
          what took hours, takes minutes. Nathan was excellent to work with. He
          responds quickly and makes sure you are happy with his work. I wouldn
          {"'"}t hesitate recommending Nathan and Radix. I know he will be our
          go to for any future needs.
          <br />
          <br />
          <i>- Jennifer Foresee, Vice President</i>
        </p>
      </div>
    </div>
  );
}

export default FeaturedClients;
