import About from "./pages/About";
import Contact from "./pages/Contact";
import FeaturedClients from "./pages/featuredClients";
import Home from "./pages/Home";
import Services from "./pages/Services";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/featured-clients",
    element: <FeaturedClients />,
  },
];

export default AppRoutes;
